module.exports = {
  //  主题设置
  theme: {
    value: {
      'layout-header-background': '#1D1E24',
      'border-radius-base': '2px',
    }
  },
  // 默认的语言
  locale: {
    store: true,
    value: 'en-us' // en-us | zh-cn
  },
  // 暗色模式
  pageStyle: {
    store: true,
    value: 'light' // 'light' | 'dark'
  }
}