import { getInfo, getTokens } from '../api/common'
import ws from '../ws'
import {
  getDefaultValue,
  setStorage,
  routeToLogin,
  routeToLogout,
} from '../lib/utils'
import heartbeat from '../lib/heartbeat'

const state = {
  isLogin: false,
  loading: false,
  locale: getDefaultValue('locale'),
  pageStyle: getDefaultValue('pageStyle'),
  csrfToken: null,
  cryptoToken: null,

  // for 通用数据
  userGroup: {},
}

const mutations = {
  SET_DATAS(state, { key, value, store = false }) {
    state[key] = value

    if (store) {
      setStorage(key, value)
    }
  },
  SET_USER_INFO(state, data) {
    state.userInfo = {
      ...state.userInfo,
      ...data,
    }
  },
}

const actions = {
  setDatas({ commit }, data) {
    commit('SET_DATAS', data)
  },
  // 获取基础信息 用户信息 + 导航权限 + 功能权限（todo）
  getBaseInfo({ commit }) {
    return getInfo({ crypted: false })
      .then((res) => {
        if (!res) return
        let data = res

        const { userInfo = {}, userGroup = {} } = data

        let newUserInfo = userInfo

        if (userInfo.metadata) {
          newUserInfo = {
            ...userInfo.metadata,
            ...userInfo,
          }
        }

        const { userGroupID, generalSetting = {} } = newUserInfo,
          { pageStyle, locale } = generalSetting

        commit('SET_USER_INFO', newUserInfo)
        commit('SET_DATAS', { key: 'userGroup', value: userGroup })
        // commit('SET_DATAS', { key: 'menus', value: getMenuList(privileges) })

        // commit('SET_DATAS', { key: 'privileges', value: privileges })
        // commit('SET_DATAS', { key: 'privilegeObject', value: privilegeObject })
        // commit('SET_DATAS', {
        //   key: 'authObject',
        //   value: formatAuth(actions, userGroup),
        // })
        // commit('SET_DATAS', { key: 'actions', value: actions })

        commit('SET_DATAS', { key: 'isLogin', value: true })

        if (pageStyle) {
          commit('SET_DATAS', { key: 'pageStyle', value: pageStyle })
        }
        if (locale) {
          commit('SET_DATAS', { key: 'locale', value: locale })
        }

        // 长连
        ws(userGroupID)
        // 心跳
        heartbeat()

        routeToLogin()
      })
      .catch(() => {
        commit('SET_DATAS', { key: 'isLogin', value: false })
        routeToLogout()
      })
  },
  getTokens({ commit }, params) {
    return getTokens(params).then((data = {}) => {
      const { csrfToken, cryptoToken } = data
      commit('SET_DATAS', { key: 'csrfToken', value: csrfToken })
      commit('SET_DATAS', { key: 'cryptoToken', value: cryptoToken })
    })
  },
  // 全局的loading
  toggleLoading({ commit }, data) {
    commit('SET_DATAS', { key: 'loading', value: data })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
