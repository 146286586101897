const enUS = require('../locales/en-us'),
  zhCN = require('../locales/zh-cn')

module.exports = {
  messages: {
    'en-us': enUS,
    'zh-cn': zhCN
  },
  // antdLocales: {
  //   'zh-cn': require('ant-design-vue/lib/locale-provider/zh_CN'),
  //   'en-us': require('ant-design-vue/lib/locale-provider/en_US')
  // },
  languages: {
    'en-us': {
      icon: '',
      name: 'English'
    },
    'zh-cn': {
      icon: '',
      name: '中文'
    }
  }
}
