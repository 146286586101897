/**
 * Axios Instance
 */
import axios from 'axios'
import { getBaseUrl } from '../lib/utils'

/*
 * @see Full config:  https://github.com/axios/axios#request-config
 */
const baseURL = getBaseUrl()

const config = {
  baseURL,
  timeout: 1000*60*3, // 3分钟 Timeout
  withCredentials: true // Check Cross-Site Access-Control
}

const instance = axios.create(config)

export default instance
