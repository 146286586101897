import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'
import { routeToLogin, routeToLogout } from '../lib/utils'
import { ROUTER_WHITE_LIST } from '../lib/constant'
import Layout from '../components/global/Layout'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/',
    redirect: '/notification',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/notification',
        name: 'Notification',
        component: () =>
          import(
            /* webpackChunkName: "notification" */ '../views/Notification.vue'
          ),
      },
      {
        path: '/notification/detail/:eventID?/:docID?',
        name: 'NotificationDetail',
        component: () =>
          import(
            /* webpackChunkName: "notification-detail" */ '../views/NotificationDetail.vue'
          ),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  const isLogin = store.state.common.isLogin
  console.log('[isLogin]', isLogin)

  if (from.name !== to.name) {
    store.dispatch('common/setDatas', { key: 'loading', value: true })
  }

  // 未登录状态且不在白名单；当路由到route指定页时，跳转至login
  if (!isLogin && ROUTER_WHITE_LIST.indexOf(to.name) === -1) {
    routeToLogout(to)
    return
  }

  // 已登录状态；当路由到login时，跳转至redirect
  if (isLogin && to.name === 'Login') {
    routeToLogin(to)
    return
  }

  next()
})

export default router
