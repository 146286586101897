import api from './index'
import store from '../store'
import { routeToLogout } from '../lib/utils'

// heartbeat
export function heartbeat() {
  return api('/api/common/heartbeat', {})
}

// 获取token
export function getTokens() {
  return api('/api/common/tokens', {
    returnToken: true
  })
}

// 全局请求，通过是否返回信息判断登陆态
export function getInfo(parameter) {
  return api('/api/common/info', { data: parameter })
}

// 登陆
export function login(parameter) {
  return api('/api/common/login', {
    data: parameter,
    method: 'post',
    entire: true
  })
}

// 退出登陆
export function logout() {
  return api('/api/common/logout', { method: 'post' })
    .then(() => {
      store.dispatch('common/setDatas', { key: 'isLogin', value: false })
      routeToLogout()
    })
    .catch(() => {})
}