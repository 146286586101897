import { heartbeat } from '../api/common'

// 10分钟检查一次是否登录失效
function checkAuth() {
  const duration = 10 * 60 * 1000 // 10分钟

  setInterval(async () => {
    await heartbeat() // api统一处理了401的情况
  }, duration)
}

export default function() {
  checkAuth()
  // todo 60分钟检查一次是否长时间没有操作?
}
