import { toRaw } from '@vue/reactivity'
import store from '../store'
import { formatEventList } from '../lib/utils'
import notification from '../lib/notification'

const tempData = {
  notificationEvent: [],
  updateDataRun: true,
}
// 收到的data是event json.
export default function (data = {}) {
  const eventArray = ['event', 'eventStart', 'eventUpdate', 'eventEnd']

  if (
    data.type === 'Quiver' &&
    data.data &&
    eventArray.indexOf(data.data.function) > -1
  ) {
    // if the notification is not muted, show it right away before modifying the list.
    if (!data.data.muted) {
      notification(window.location.origin, data.data)
    }
    // notice计数但列表不展示
    tempData.notificationEvent.unshift(data)

    // 数据处理
    const handleUpdateData = function () {
      // notification
      if (tempData.notificationEvent.length) {
        const tempNotificationEvent = {}

        tempData.notificationEvent.forEach((item = {}) => {
          const { handleStatus = 'pending', muted } = item

          // 数据分类
          if (muted) {
            if (!tempNotificationEvent['muted']) {
              tempNotificationEvent['muted'] = []
            }

            tempNotificationEvent['muted'].push(item)
          } else if (item.data.function === 'eventEnd') {
            if (!tempNotificationEvent['ended']) {
              tempNotificationEvent['ended'] = []
            }
            tempNotificationEvent['ended'].push(item)
          } else {
          
            if (!tempNotificationEvent[handleStatus]) {
              tempNotificationEvent[handleStatus] = []
            }

            tempNotificationEvent[handleStatus].push(item)
          }
        })

        console.log(
          'Attempting to show notification for events:',
          tempNotificationEvent
        )
        for (let i in tempNotificationEvent) {
          const eventLength = store.state.event[`${i}EventList`].length,
            newEventList = formatEventList(
              tempNotificationEvent[i],
              toRaw(store.state.event[`${i}EventList`])
            )

          // 刷新个数
          store.dispatch('event/setDatas', {
            key: `${i}Total`,
            value:
              store.state.event[`${i}Total`] +
              newEventList.length -
              eventLength,
          })
          // 刷新列表
          store.dispatch('event/setDatas', {
            key: `${i}EventList`,
            value: newEventList,
          })

          // 页面滚动到最顶部
          // 因为用户在操作页面，影响体验，页面不滚动到页面最顶部
          // TODO： 这个用In App notification 来实现。
          // document.getElementById('app').scrollTo({ top: 0 })

          // 浏览器通知
          // console.log(
          //   'Calling notification with message:',
          //   tempNotificationEvent[i][0]?.data?.eventMsg
          // )
          // if (tempNotificationEvent[i].length > 0) {
          //   // Get the latest event message
          //   const latestEvent = tempNotificationEvent[i][0]
          //   const eventMsg =
          //     latestEvent.data?.eventMsg || 'You have new event in Artemis.'

          //   // Pass both origin and message to notification
          //   notification(window.location.origin, eventMsg)
          // }
        }

        tempData.notificationEvent = []
      }
    }

    // notification & 页面数据处理(节流)
    const updateData = function () {
      if (!tempData.updateDataRun) return
      tempData.updateDataRun = false

      setTimeout(() => {
        handleUpdateData()
        tempData.updateDataRun = true
      }, 1000)
    }
    updateData()
  }
}
