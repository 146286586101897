import moment from 'moment'
import config from '../config/config'
import router from '../router'
import { DEFAULT_TIME_FORMAT } from './constant'

// 获取url前缀，用是否/开头 判断 判断是否完整地址
export function getUrlPrefix(url) {
  if (!url) return null

  if (url.startsWith('/')) {
    return `${location.host}${url}`
  } else {
    // 全地址
    return url
  }
}

// 获取请求的基础链接
export function getBaseUrl() {
  const apiUrl =
      process.env.VUE_APP_API_HOST ||
      getUrlPrefix(window.artemis.config.apiHost),
    baseURL = apiUrl ? `${location.protocol}//${apiUrl}` : ''
  return baseURL
}

// 深拷贝
export function deepCopy(data) {
  if (!data) return null
  return JSON.parse(JSON.stringify(data))
}

// 格式化时间戳
export function formatTimestamp(timestamp, format = DEFAULT_TIME_FORMAT) {
  if (!timestamp) return '-'
  return moment(timestamp * 1).format(format)
}

// 转换为数字格式的时间戳
export function transferToTimestamp(date = new Date()) {
  if (!date) return ''

  const timestamp = moment(date).format('x')
  return timestamp * 1
}

// 防抖
export function debounce(fn, duration = 500) {
  let timer = null

  return function () {
    clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(this, arguments)
    }, duration)
  }
}

// 节流
export function throttle(fn, duration = 500) {
  let run = true

  return function () {
    if (!run) return
    run = false

    setTimeout(() => {
      fn.apply(this, arguments)
      run = true
    }, duration)
  }
}

// 统一存储的key
export function getStorageKey(key) {
  return `ARTEMIS_APP_${key.toUpperCase()}`
}

// 设置local storage
export function setStorage(key, value) {
  const sKey = getStorageKey(key)
  localStorage[sKey] = value
}

// 获取local storage
export function getStorage(key, parse = false) {
  const sKey = getStorageKey(key),
    sValue = localStorage[sKey]

  if (sValue) {
    return parse ? JSON.parse(sValue) : sValue
  } else {
    return null
  }
}

// 获取默认值
export function getDefaultValue(key, parse = false) {
  return getStorage(key, parse) || (config && config[key] && config[key].value)
}

// 登录跳转
export function routeToLogin(route = router.currentRoute.value) {
  const { redirect } = route.query,
    routerName = route.name

  if (!routerName) return

  if (redirect) {
    router.push({ name: redirect }, () => {})
  } else {
    router.push({ path: '/' }, () => {})
  }
}

// 退出登录
export function routeToLogout(route = router.currentRoute.value) {
  const query = {},
    routerName = route.name

  if (routerName === 'Login') return

  if (routerName !== '403') {
    query.redirect = routerName
  }

  router.push(
    {
      name: 'Login',
      query,
    },
    () => {
      window.location.reload()
    }
  )
}

// agg-event数据整理
export function formatAggEventList(list = []) {
  const eventList = []

  list.forEach((item = {}) => {
    const { docs = [] } = item

    // 按照tsUnix倒序
    docs.sort((a = {}, b = {}) => {
      const { data: aData = {} } = a,
        { data: bData = {} } = b

      return bData.tsUnix * 1 - aData.tsUnix * 1
    })

    const latestDoc = docs[0] || {}, // 获取聚合里最新的文档
      { objects = [] } = latestDoc.data || {},
      object = objects[0] || {},
      { attrs = {} } = object

    const newItem = {
      ...latestDoc,
      attrs,
    }
    eventList.push(newItem)
  })

  return eventList
}

// event数据整理
export function formatEventList(list = [], eventList = []) {
  const newEventList = deepCopy(eventList)
  list.forEach((item = {}) => {
    formatEvent(item, newEventList)
  })

  return newEventList
}

// 单条数据格式整理
export function formatEvent(doc = {}, eventList = []) {
  const { data = {} } = doc,
    { objects = [] } = data,
    object = objects[0] || {},
    { attrs = {} } = object

  const defaultTempObject = {
    ...doc,
    attrs,
  }

  // 原子事件
  if (data.function === 'event') {
    eventList.unshift(defaultTempObject)
  } else {
    // 持续性事件
    let target = -1,
      targetEvent = null

    // 是否在当前的eventList中
    for (let i = 0; i < eventList.length; i++) {
      if (eventList[i].data.eventID === data.eventID) {
        target = i
      }
    }

    // 不存在当前的eventList中
    if (target === -1) {
      targetEvent = defaultTempObject
    } else {
      targetEvent = eventList[target]
      eventList.splice(target, 1)
    }

    eventList.unshift(targetEvent)
  }

  return doc
}

// event detail数据整理
export function formatEventDetail(event = {}, docID = null) {
  const { docs = [] } = event,
    currentDoc = docs.filter((item) => item.docID === docID)[0] || {}

  const { objects = [] } = currentDoc.data || {},
    object = objects[0] || {},
    { attrs = {} } = object

  const newEvent = {
    ...currentDoc,
    attrs,
  }

  return newEvent
}
