import qs from 'qs'
import store from '../store'

let wsTimer = null,
  count = 0

// 实例websocket（必须都做长连，用来感知后端重启获取token）
export default function createWebSocket(url, userGroupID, handleMessage) {
  try {
    const { drawObjClass } = window.artemis.config,
      urlParams = {
        userGroupID,
        drawObjClass
      }
    const ws = new WebSocket(`${url}?${qs.stringify(urlParams)}`)

    ws.onopen = function() {
      console.log('[ON OPEN] Opened conn:', url, new Date())

      // 获取token
      store.dispatch('common/getTokens', {})

      // 防止断连
      wsTimer = setInterval(() => {
        ws.send(0)
      }, 45 * 1000)
    }

    ws.onmessage = function(event) {
      try {
        if (event.data) {
          const data = JSON.parse(event.data)
          console.log('[ws]message:', data)

          if (handleMessage && typeof handleMessage === 'function') {
            handleMessage(data)
          }
        }
      } catch (e) {
        console.error('[MSG ERROR] ' + e)
        return
      }
    }

    ws.onclose = function() {
      reconnect(url, userGroupID, handleMessage)
      console.log('[ON CLOSE] Disconnected:', url, new Date())
    }

    ws.onerror = function(error) {
      console.error('[ON ERR] Sorry connection cannot be opened: ', error)
    }
  } catch (e) {
    console.error(e)
  }
}

// 重连
function reconnect(url, userGroupID, handleMessage) {
  count++
  clearInterval(wsTimer)
  wsTimer = null

  if (count >= 50) return

  setTimeout(function() {
    createWebSocket(url, userGroupID, handleMessage)
  }, 2000)
}
