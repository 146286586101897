import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "layout"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_van_back_top = _resolveComponent("van-back-top");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view), _createVNode(_component_van_back_top, {
    bottom: 15
  })]);
}