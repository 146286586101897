module.exports = {
  // 全局
  global: {
    loading: 'Loding',
  },
  // 异常
  exception: {
    statusCode: {
      404: 'Sorry, the page you visited does not exist.',
      403: 'Permission denied.',
    },
    back: 'Back Home',
  },
  // 登陆
  login: {
    username: {
      text: 'Email',
      rules: {
        required: 'Email is required',
      },
    },
    password: {
      text: 'Password',
      rules: {
        required: 'Password is required',
      },
    },
    rememberMe: 'Remember Me',
    submit: 'Sign in',
    conflictLogin:
      'The current user name has been logged in. Do you want to exit the previous login?',
    factorAuthentication: {
      title: 'Enter Your OTP',
      otp: {
        placeholder: 'Enter OTP Code',
        extra: 'OTP expires in 5 minutes',
        btnText: 'Send OTP',
      },
    },
  },
  // 统一的消息
  messages: {
    200: 'Success',
    500: 'Fail',
    400: 'Invalid parameter',
    401: 'Unauthorized',
    403: 'Permission denied',
    409: 'Conflict',
    loginFail: 'Login failed: Invalid username or password.',
    voiceMessage: 'New voice message, click here to receive.',
    invalidForm: 'Please complete the form',
    noData: 'No data.',
    sync: 'The operation is successful and is running in the background.',
    copy: 'Copied to clipboard',
    fixSubmitted:
      'Fix submitted, It takes a while for fix to take effect. Refresh page in a while to check fix status.',
    unfixSubmitted:
      'Unfix submitted, It takes a while to take effect. Refresh page in a while to check fix status.',
    settingSuccess: 'Setting modifed. Refresh page to take effect.',
    conflictConfirm: 'Version conflict, obtaining the latest data.',
    sendEmailOTPSuccess: 'Send an OTP on # success.',
    noOptions: 'Please select options.',
  },
  // 空态
  empty: {
    noResult: 'No results found',
    noMedia: 'No Media',
  },

  // notification
  notification: {
    messages: {
      handleStatus: 'Are you sure to change handle status ?'
    }
  }
}
