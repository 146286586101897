import icon from '../assets/logo_dark_collapse.png'

const notification = async function (origin, data) {

  const vibratePattern = [500, 500, 500]
  if ('serviceWorker' in navigator) {
    console.log('[lib-notification]', data)
    try {
      const registration = await navigator.serviceWorker.ready
      if (!registration.showNotification) {
        console.log('Notifications not supported in this browser')
        return
      }
      const eventMsg = data.eventMsg || 'You have new event in Artemis.'
      registration.showNotification(eventMsg, {
        body: data?.eventInfo || '',
        icon: data?.media?.resizedImgPath || '', // Make sure to use correct icon path
        badge: icon,
        tag: 'Artemis Notification', // Optional: groups similar notifications
        data: { url: origin }, // Optional: data to use when notification is clicked
        vibrate: vibratePattern, // Vibrate pattern: vibrate 200ms, pause 100ms, vibrate 200ms
        // sound: '/tip.mp3', // Use the default notification sound from user phone.
        silent: false,
        requireInteraction: true,
        renotify: true,
        timestamp: Date.now(),
        priority: 'high',
        importance: 'high',
      })

      if ('vibrate' in navigator) {
        navigator.vibrate(vibratePattern)
      }

      // Try playing sound directly
      // const audio = new Audio('/tip.mp3')
      // audio.play().catch((e) => console.log('Sound play error:', e))

      console.log('WebSocket notification shown:')
    } catch (err) {
      console.error('Failed to show notification:', err)
    }
  }
  // Notification.requestPermission(function (result) {
  //   if (result === 'granted') {
  //     navigator.serviceWorker.ready.then(function (registration) {
  //       registration.showNotification('Artemis Notification')
  //     })
  //   }
  // })
}
export default notification

