module.exports = {
  // 全局
  global: {
    loading: '加载中',
  },
  // 异常
  exception: {
    statusCode: {
      404: '抱歉，你访问的页面不存在。',
      403: '没有权限',
    },
    back: '返回首页',
  },
  // 登陆
  login: {
    username: {
      text: '邮箱',
      rules: {
        required: '请输入邮箱',
      },
    },
    password: {
      text: '密码',
      rules: {
        required: '请输入密码',
      },
    },
    rememberMe: '自动登录',
    submit: '登陆',
    conflictLogin: '当前用户名已被登陆，是否退出之前的登陆？',
    factorAuthentication: {
      title: '输入验证码',
      otp: {
        placeholder: '输入验证码',
        extra: '验证码5分钟后过期',
        btnText: '发送验证码',
      },
    },
  },
  // 统一的消息
  messages: {
    200: '成功',
    500: '失败',
    400: '无效参数',
    401: '未登陆',
    403: '被禁止或没有权限',
    409: '冲突',
    loginFail: '登陆失败：无效的账号或密码',
    voiceMessage: '有新的语音消息，点此接收',
    invalidForm: '请完善表单内容',
    noData: '没有数据',
    sync: '操作成功，后台运行中',
    copy: '复制到剪贴板',
    fixSubmitted:
      '已提交修复，修复需要一段时间才能生效。稍后刷新页面以检查修复状态。',
    unfixSubmitted:
      '已提交取消修复，需要一段时间才能生效。稍后刷新页面以检查修复状态。',
    settingSuccess: '成功，刷新页面生效。',
    conflictConfirm: '版本冲突，获取最新的数据。',
    sendEmailOTPSuccess: '发送验证码到 # 成功。',
    noOptions: '请选择要下载的选项.',
  },
  // 空态
  empty: {
    noResult: '没有查询结果',
    noMedia: '没有媒体',
  },

  // notification
  notification: {
    messages: {
      handleStatus: '确定要更改句柄状态吗？'
    }
  }
}
