import api from './index'

// 历史记录/初始态查询
export function getEventList(parameter) {
  return api('/api/aggEvent/query', {
    data: parameter
  })
}

// 修改Handle Status & Muted
export function updateHandleStatus(parameter) {
  return api('/api/aggEvent/handleStatus', {
    data: parameter,
    method: 'post'
  })
}

// event detail 查询
export function getEventDetail(parameter) {
  return api('/api/aggEvent/detail', {
    data: parameter
  })
}