import http from '../lib/http'
import store from '../store'
import { routeToLogout } from '../lib/utils'

export default async function(url, payload = {}) {
  const {
      method = 'get',
      data = {},
      entire = false,
      headers = {},
      returnToken = false
    } = payload,
    isGet = method.toLowerCase() === 'get',
    conditions = {}

  if (isGet) {
    conditions.params = data
  } else {
    conditions.data = data
  }

  return http({
    url,
    method,
    headers: {
      ...headers,
      'CSRF-Token': store.state.common.csrfToken
    },
    ...conditions
  })
    .then((response = {}) => {
      const { data: res, headers: resHeaders } = response

      // 返回Token
      if (returnToken) {
        return {
          csrfToken: resHeaders['artemis-csrf-token'],
          cryptoToken: resHeaders['artemis-crypto-token'],
        }
      }

      // 请求401自动跳转到登陆页面
      if (res && res.code === 401) {
        store.dispatch('common/setDatas', { key: 'isLogin', value: false })
        routeToLogout()
        return
      }

      // 返回请求的全部内容
      if (entire) {
        return res
      }
      // 没有特殊需求直接返回数据部分
      if (res && res.code === 200) {
        return res.data
      } else {
        throw res
      }
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
        store.dispatch('common/setDatas', { key: 'isLogin', value: false })
        routeToLogout()
      }
      // 确保往下可以继续catch
      throw error
    })
}
