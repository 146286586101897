import createWebSocket from './ws'
import { getUrlPrefix } from '../lib/utils'
import notificationCallback from './notification'

export default function(userGroupID) {
  const protocolMapping = {
      'http:': 'ws:',
      'https:': 'wss:'
    },
    wsProtocol = `${protocolMapping[window.location.protocol] || 'ws:'}//`

  const NS_HOST = `${wsProtocol}${getUrlPrefix(window.artemis.config.wsHost)}`

  // backend
  createWebSocket(NS_HOST, userGroupID, data => {
    notificationCallback(data)
  })
}
