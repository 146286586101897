import { getEventList, getEventDetail } from '../api/event'
import { deepCopy, formatAggEventList, formatEventDetail } from '../lib/utils'
import { DEFAULT_PAGINATION } from '../lib/constant'

const state = {
  pendingEventList: [],
  pendingTotal: 0,
  acknowledgedEventList: [],
  acknowledgedTotal: 0,
  doneEventList: [],
  doneTotal: 0,
  mutedEventList: [],
  mutedTotal: 0,
  endedEventList: [],
  endedTotal: 0,
  eventDetail: {}
}

const mutations = {
  SET_DATAS(state, { key, value }) {
    state[key] = value
  },
}

const actions = {
  async getEventData({ commit, state }, params = {}) {
    const { tabKey, ...other } = params,
      conditions = {
        ...other,
      }

    if (!params.pagination) {
      conditions.pagination = deepCopy(DEFAULT_PAGINATION)
    }

    const { total, data } = await getEventList(conditions).catch(() => {})
    const currentEventList = formatAggEventList(data),
      eventList = state[`${tabKey}EventList`].concat(currentEventList)

    commit('SET_DATAS', { key: `${tabKey}EventList`, value: eventList })
    commit('SET_DATAS', { key: `${tabKey}Total`, value: total })
  },
  async getEventDetail({ commit }, params = {}) {
    const { eventID, docID } = params
    const data = await getEventDetail({ eventID }).catch(() => {})

    if (data && Object.keys(data).length) {
      const eventDetail = formatEventDetail(data, docID)

      commit('SET_DATAS', { key: 'eventDetail', value: eventDetail })
      return eventDetail
    }
  },
  // 设置数据
  setDatas({ commit }, data) {
    commit('SET_DATAS', data)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
