import { createStore } from 'vuex'
import common from './common'
import event from './event'

export default createStore({
  modules: {
    common,
    event
  },
})
